require('./bootstrap');

$(document).ready(function () {
    var editor = new MediumEditor('.editable');
   /* editor.subscribe('editableInput', function (eventObj, editable) {
        let content = editor.getContent();
        // Ajax call to update your database
    });*/

    $('.owl-carousel').owlCarousel({
        loop:true,
        margin:25,
        autoplay:true,
        nav:false,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:3
            },
            1000:{
                items:5
            }
        }
    })


});

